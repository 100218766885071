import getConfig from "next/config";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
// In the browser, `window.location.host` is trusted, so default to `*` there.
const { ALLOWED_HOSTS = ["*"] } = serverRuntimeConfig;
const { APP_HOST } = publicRuntimeConfig;

const FALLBACK_HOST = "vrresa.se";

// Used by _document.js and _app.js.
export function extractUrlData(req) {
  const rawHost = req != null ? req.headers.host : window.location.host;

  const allowed = ALLOWED_HOSTS.some(
    (allowedHost) => rawHost === allowedHost || allowedHost === "*"
  );

  if (!allowed) {
    console.warn(
      "Host header is not present in ALLOWED_HOSTS. Using fallback host.\n",
      {
        rawHost,
        ALLOWED_HOSTS,
        FALLBACK_HOST,
        headers:
          req != null ? req.headers : "(no headers available in browser)",
      }
    );
  }

  const host = allowed ? rawHost : FALLBACK_HOST;

  const protocol =
    (req != null ? req.protocol : window.location.protocol.replace(/:$/, "")) ??
    "https";

  return {
    baseUrl: `${protocol}://${host}`,
    appMode: host === APP_HOST,
  };
}

/**
 * Extracts the `x-nonce` header from the request.
 *
 * @param {Request | undefined} req
 * @returns {string | undefined}
 */
export function extractXNonce(req) {
  return req?.headers["x-nonce"] ?? undefined;
}
