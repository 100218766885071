import { addHours } from "date-fns";
import cookies from "js-cookie";
import React from "react";
import { Sentry } from "sentry";

import { useAppContext } from "#components/AppContext";
import topNotification from "#components/topNotification";
import { EMPTY_JOURNEY, WarningStatus } from "#containers/Booking/consts";
import {
  collectDepartureIds,
  countsToPassengersArray,
  makeDate,
  makeDeparturesKey,
} from "#containers/Booking/helpers";
import { getBookingSessionCookieName } from "#containers/Booking/helpers";
import { saveBookingSession } from "#containers/Booking/helpers/saveBookingSession";
import { cookiesEnabled, deleteNil } from "#utils/";

import {
  COOKIES_DISABLED_ERROR,
  CREATE_SESSION_ERROR,
  CREATE_SESSION_ERROR_NETWORK,
  RESET_DEPARTURES_ERROR_PRICING_EXPIRED,
  RESTART_SESSION_ERROR_FULL,
  WARNING_INBOUND_BEFORE_ARRIVAL,
  WARNING_INBOUND_CLOSE_TO_ARRIVAL,
  WARNING_TRAIN_HAS_DEPARTED,
} from "../../nodes";
import { useBooking } from "..";
import useBookingFetchers from "./useBookingFetchers";
import useGetScrollToTop from "./useGetScrollToTop";

const WARNING_INBOUND_TIME = 2;

export default function useDeparturesHandlers() {
  const {
    booking,
    departures,
    setBooking,
    setDepartures,
    setLoading,
    setSession,
    voucher,
    event,
    forward,
    rebookJourneyId,
    openNewSessionWarning,
  } = useBooking();
  const { extras, outbound, inbound, counts, from, to, showInbound } = booking;
  const { api, language } = useAppContext();
  const scrollToTop = useGetScrollToTop();

  const { getDepartures } = useBookingFetchers();

  const outboundKey = makeDeparturesKey(from.id, to.id, outbound.date);
  const inboundKey = makeDeparturesKey(to.id, from.id, inbound.date);

  const resetDepartures = async () => {
    try {
      setLoading(true);
      const commonParams = deleteNil({
        counts,
        extras,
        voucher,
        rebook_journey_id: rebookJourneyId,
      });

      const [outboundDepatures, inboundDepartures] = await Promise.all([
        getDepartures({
          from,
          to,
          date: outbound.date,
          forOutbound: true,
          ...commonParams,
        }),

        showInbound
          ? getDepartures({
              from: to,
              to: from,
              date: inbound.date,
              forOutbound: false,
              ...commonParams,
            })
          : undefined,
      ]);

      const newDeparturesState = { ...departures };

      if (outboundDepatures) {
        newDeparturesState[outboundKey] = outboundDepatures;
      }
      if (inboundDepartures) {
        newDeparturesState[inboundKey] = inboundDepartures;
      }

      setDepartures(newDeparturesState);
      setBooking({
        ...booking,
        outbound: {
          ...outbound,
          ...EMPTY_JOURNEY,
        },
        inbound: {
          ...inbound,
          ...EMPTY_JOURNEY,
        },
      });
      setLoading(false);
    } catch (error) {
      console.error(error, error.response);
      setLoading(false);
    }
  };

  const determineDepartureWarnings = (passedId) => {
    const outboundJourneys = departures[outboundKey] || [];
    const inboundJourneys = departures[inboundKey] || [];

    const noWarnings = { status: WarningStatus.ALLOWED };
    const isConfirmed = ({ bookingClass, journey }) =>
      bookingClass != null && journey?.id != null;

    const clickedOutboundRow = outboundJourneys.find(
      ({ id }) => id === passedId
    );
    const clickedInboundRow = inboundJourneys.find(({ id }) => id === passedId);

    const row =
      clickedOutboundRow == null ? clickedInboundRow : clickedOutboundRow;

    if (row.passed) {
      return {
        display: WARNING_TRAIN_HAS_DEPARTED,
        status: WarningStatus.WARNING,
      };
    }

    if (
      (isConfirmed(outbound) && clickedInboundRow) ||
      (isConfirmed(inbound) && clickedOutboundRow)
    ) {
      const findOutboundDepature = clickedInboundRow
        ? outboundJourneys.find(({ id }) => id === outbound.journey.id)
        : undefined;

      const findInboundDeparture = clickedOutboundRow
        ? inboundJourneys.find(({ id }) => id === inbound.journey.id)
        : undefined;

      const confirmedOutboundDepature =
        findOutboundDepature || clickedOutboundRow;
      const confirmedInboundDepature =
        findInboundDeparture || clickedInboundRow;

      if (
        confirmedOutboundDepature?.arrival_at &&
        confirmedInboundDepature?.departure_at
      ) {
        const outboundTime = makeDate(
          confirmedOutboundDepature.arrival_at
        ).getTime();
        const inboundTime = makeDate(
          confirmedInboundDepature.departure_at
        ).getTime();

        if (inboundTime < outboundTime) {
          return {
            display: WARNING_INBOUND_BEFORE_ARRIVAL,
            status: WarningStatus.NOT_ALLOWED,
          };
        } else if (
          inboundTime <
          addHours(new Date(outboundTime), WARNING_INBOUND_TIME).getTime()
        ) {
          return {
            display: WARNING_INBOUND_CLOSE_TO_ARRIVAL,
            status: WarningStatus.WARNING,
          };
        }
      }
      return noWarnings;
    }
    return noWarnings;
  };

  const attemptDepartureSubmit = async () => {
    if (cookies.get(getBookingSessionCookieName())) {
      openNewSessionWarning({
        onConfirm: onDeparturesSubmit,
        onDismiss: () => null,
      });
    } else {
      await onDeparturesSubmit();
    }
  };

  const onDeparturesSubmit = async () => {
    // In Sentry, there a bunch of "missing session" errors, due to the cookie
    // with the booking session ID not being found. It’s actually possible to
    // completely disable cookies in many browsers. If so, don’t allow starting
    // a session at all since it won’t be possible to finish the booking. Let’s
    // see if this reduces the number of errors in Sentry.
    if (!cookiesEnabled()) {
      topNotification(COOKIES_DISABLED_ERROR);
      return;
    }

    setLoading(true);

    let session = undefined;

    try {
      ({ data: session } = await api.createBookingSession(
        deleteNil({
          journey: collectDepartureIds(outbound.journey.departures),
          booking_class: outbound.bookingClass,
          passengers: countsToPassengersArray(counts),
          has_stroller: extras.stroller,
          event,
          has_pet: extras.pet,
          wheelchairs: extras.wheelchairs,
          walkers: extras.walkers,
          voucher_code: voucher?.code,
          psp_locale: language,
          rebook_journey_id: rebookJourneyId,
        })
      ));

      if (inbound.journey?.id != null && inbound.bookingClass != null) {
        ({ data: session } = await api.addInboundToBookingSession({
          sessionId: session.id,
          journey: collectDepartureIds(inbound.journey.departures),
          booking_class: inbound.bookingClass,
        }));
      }

      saveBookingSession({
        sessionId: session.id,
        expires: new Date(session.validUntil.localTimestamp),
      });

      setLoading(false);
      setSession(session);
      forward();
    } catch (error) {
      console.error("Booking: Failed to create session", error, error.response);
      setLoading(false);
      const status = error?.response?.status;
      const data = error?.response?.data;

      const dataIsArray = Array.isArray(data);
      const journeyPricingExpired =
        dataIsArray &&
        data.some(({ code }) => code === "journey_pricing_expired");

      if (journeyPricingExpired) {
        topNotification(RESET_DEPARTURES_ERROR_PRICING_EXPIRED);
        await resetDepartures();
        scrollToTop();
      } else if (status === 409) {
        // The departure has become full while the user decided which departure
        // to choose. Show a message specifically for this case, reset the
        // not-available choice and mark the departure as unavailable.
        topNotification(React.cloneElement(RESTART_SESSION_ERROR_FULL));
        resetDepartures();
      } else if (dataIsArray) {
        topNotification(data.map(({ detail }) => detail).join(", "));
      } else if (status != null) {
        topNotification(React.cloneElement(CREATE_SESSION_ERROR, { status }));
      } else if (error.noResponse) {
        error.message = `Network error: ${error.message}`;
        topNotification(CREATE_SESSION_ERROR_NETWORK);
      } else {
        topNotification(
          React.cloneElement(CREATE_SESSION_ERROR, { status: 1500 })
        );
      }
      Sentry.captureException(error);
    }
  };

  return {
    onDeparturesSubmit: attemptDepartureSubmit,
    determineDepartureWarnings,
    resetDepartures,
  };
}
