/**
 * Adds `queryString` at the end of `url` (which might already have a query
 * string), taking care of the question mark.
 *
 * @param {string} url
 * @param {string} queryString
 * @returns {string}
 */
export function addQueryString(url, queryString) {
  const strippedUrl = url.replace(/\?$/, "");
  const separator = strippedUrl.includes("?") ? "&" : "?";
  return queryString === "" ? url : `${strippedUrl}${separator}${queryString}`;
}

/**
 * Concatenates `path` to `url`.
 *
 * @param {string} url
 * @param {string} path
 * @returns {string}
 */
export function concatPath(url, path) {
  return `${url.replace(/\/$/, "")}/${path.replace(/^\//, "")}`;
}

/**
 * Tries to parse `url` and returns the result. If `url` is invalid it returns `undefined`.
 *
 * @param {string} url
 * @returns {URL | undefined}
 */
export function tryParseURL(url) {
  try {
    return new URL(url);
  } catch {
    return undefined;
  }
}
