/**
 * Transforms a language string to a language and locale string: `xx-SE`.
 * The locale is always set to "SE".
 *
 * @param {string | undefined} language
 * @returns string
 */
export function getLanguageAndLocaleFromLanguage(language) {
  switch (language?.toLowerCase()) {
    case "en":
      return "en-SE";
    case "sv":
      return "sv-SE";
    case "fi":
      return "fi-SE";
    default:
      return `${language ?? "sv"}-SE`;
  }
}
